<div
  class="w-full h-full bg-gradient-to-r from-brand to-violet-500 text-darkest rounded-lg flex flex-col justify-center p-6 px-8 gap-2 relative isolate">
  <!-- <h1 class="text-4xl font-extrabold text-left text-lightest w-full z-10">.</h1>
  <h5 class="text-sm font-medium text-left text-lightest w-full z-10">
    Turn long-form content into short, viral videos in minutes.
  </h5> -->
  <!-- <input type="text" class="input w-64 z-10 p-2" placeholder="Enter a URL" /> -->
  <!-- <div class="bg-white border w-full h-10 rounded-lg"></div> -->
  <div class="flex justify-between">
    <div class="flex flex-col gap-2">
      <div class="flex gap-2 w-full items-center text-lighter z-10">
        <ng-icon name="heroSparklesSolid" class="text-xl" />
        <span class="font-light">AI Creation</span>
      </div>
      <h1 class="text-4xl font-extrabold text-left text-lightest w-full z-10">Create video with AI.</h1>
      <h5 class="text-sm font-medium text-left text-lightest w-full z-10 mb-2">
        Turn long-form content into short, viral videos in minutes.
      </h5>
    </div>
    <div class="flex flex-col justify-center gap-4 items-end">
      <div class="input px-2 py-1 flex items-center w-72 z-10">
        <ng-icon name="heroLink" class="" />
        <input class="grow no-focus text-sm" placeholder="Enter a YouTube URL" />
      </div>
      <button class="button-secondary text-xs w-fit h-fit z-10 flex items-center" (click)="openCreationOverlay()">
        <ng-icon name="heroCloudArrowUp" class="mr-2 -ml-1 text-xl h-0" />
        Upload video
      </button>
    </div>
  </div>
  <div class="inset-0 absolute grid-bg"></div>
</div>
