<ui-card class="p-6 px-6 w-full">
  <h1 class="text-dark text-3xl font-bold mb-6 mt-3">Generate video with AI</h1>
  <div class="w-full lg:px-0 mx-auto mt-4">
    <div class="w-full mb-8 rounded-lg flex drop-shadow-lg items-center">
      <div class="border border-r-0 rounded-r-none grow h-full py-4 rounded-lg flex bg-white">
        <div class="flex items-center">
          <ng-icon
            name="heroCloudArrowUp"
            class="text-neutral-500 text-xl h-6 w-6 ml-4 mr-1 cursor-pointer"
            (click)="openUploadDialog()" />
        </div>
        <input
          class="font-light px-2 sm:pl-2 pl-1 grow no-focus"
          [placeholder]="placeholder()"
          [ngxTippy]="'We support YouTube videos, and Twitch clips/VODs. You can also upload your own video.'"
          [tippyProps]="{ delay: [300, 0] }"
          [formControl]="videoUrl" />
      </div>
      <button
        class="disabled:opacity-75 disabled:cursor-not-allowed bg-brand background-brand border border-brand rounded-r-lg border-l-0 px-4 sm:px-6 text-white h-full py-3.5 block sm:text-lg w-fit z-10 flex items-center focus:outline-none shrink-0"
        [disabled]="videoUrl.invalid"
        (click)="openCreationOverlay()">
        <div class="sm:flex hidden items-center">
          <ng-icon name="heroSparklesSolid" class="mr-2 -ml-1 text-lg sm:text-xl h-0" />
        </div>
        <div class="font-bold">Create</div>
      </button>
    </div>
  </div>
  <div class="my-8 h-px bg-neutral-100 w-full"></div>
  <div class="flex justify-between items-center gap-8">
    <div>
      <a
        routerLink="/video/"
        fragment="recent"
        class="block w-fit text-neutral-400 tracking-widest uppercase text-xs font-light hover:text-mid transition-colors default-ring">
        View all
        <ng-icon name="heroArrowTopRightOnSquare" class="inline-block" aria-hidden="true"></ng-icon>
      </a>
      <h1 class="text-dark text-2xl font-bold mt-1">Recent projects</h1>
    </div>
    <a
      routerLink="/video/"
      fragment="recent"
      class="text-neutral-400 transition-colors items-center justify-center flex w-10 h-10 hover:bg-lighter default-ring rounded-full text-2xl focus:bg-lighter focus:transition-none focus-visible:bg-lighter">
      <ng-icon name="heroChevronRight" class="inline-block" aria-hidden="true"></ng-icon>
    </a>
  </div>
  <div class="mt-8 gap-8 flex flex-col h-[24rem]">
    <ng-container *ngIf="error; else noError">
      <div *ngIf="!loading" class="grow items-center justify-center flex flex-col">
        <svg
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="h-[5.25rem] aspect-square text-neutral-200 mb-2">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path
              d="M5.31171 10.7615C8.23007 5.58716 9.68925 3 12 3C14.3107 3 15.7699 5.58716 18.6883 10.7615L19.0519 11.4063C21.4771 15.7061 22.6897 17.856 21.5937 19.428C20.4978 21 17.7864 21 12.3637 21H11.6363C6.21356 21 3.50217 21 2.40626 19.428C1.31034 17.856 2.52291 15.7061 4.94805 11.4063L5.31171 10.7615Z"
              stroke="currentColor"
              stroke-width="1.5"></path>
            <path d="M12 8V13" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path>
            <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
          </g>
        </svg>
        <div class="text-neutral-700 font-medium mb-1">An error occurred</div>
      </div>
    </ng-container>
    <ng-template #noError>
      <ng-container *ngIf="recentProjects.length > 0; else noProjects">
        <ng-container *ngFor="let project of recentProjects">
          <div
            [routerLink]="['/video/project/', project.uuid]"
            class="flex items-center gap-4 block rounded-lg hover:bg-neutral-50 transition-colors cursor-pointer p-2">
            <button
              class="h-[5.55rem] aspect-video bg-neutral-100 rounded-lg flex items-center justify-center shrink-0 default-ring overflow-hidden">
              @if (project.image) {
              <img [src]="project.image" class="h-full select-none" />
              } @else {
              <img src="assets/img/svg/no-image.svg" class="h-full select-none max-h-[2rem]" />
              }
            </button>
            <div class="grow text-left truncate">
              <span class="truncate font-semibold h-5 text-darker">
                {{ project.name }}
              </span>
              <div class="flex gap-2 items-center">
                <div class="flex items-center gap-1 mt-1 truncate">
                  <ng-icon name="heroCalendar" class="text-neutral-500 text-sm h-4 w-4 mb-0.5" aria-hidden="true" />
                  <span class="text-sm text-neutral-600 font-light">{{ getDateString(project.created) }}</span>
                </div>
                <span class="text-neutral-300">•</span>
                <div class="flex items-center gap-1 mt-1 truncate">
                  <ng-icon name="heroVideoCamera" class="text-neutral-500 text-sm h-4 w-4" aria-hidden="true" />
                  <span class="text-sm text-neutral-600 font-light">{{ project.videos.length }} clips</span>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #noProjects>
        <div *ngIf="!loading" class="grow items-center justify-center flex flex-col">
          <svg
            class="h-[5.25rem] aspect-square text-neutral-200 mb-2"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="currentColor"
            viewBox="0 0 520 520">
            <g>
              <path
                d="M505.29,157.622c-9.005-5.568-20.585-6.075-30.037-1.342L397,195.244v-42.185c0-16.862-13.256-30.136-30.118-30.136   H183.734l-68.365-80.99c-10.883-12.866-30.131-14.591-43.027-3.685C59.476,49.14,57.866,68.36,68.764,81.233l335.867,396.909   c6.038,7.134,14.641,10.797,23.318,10.797c6.962,0,13.97-2.377,19.71-7.23c12.866-10.891,14.276-30.164,3.378-43.038L397,375.045   v-19.903l78.136,38.964c4.309,2.154,9.098,3.22,13.764,3.22c5.576,0,11.435-1.528,16.34-4.562   c8.99-5.561,14.76-15.386,14.76-25.971v-183.2C520,173.007,514.28,163.183,505.29,157.622z" />
              <path
                d="M0,153.059v244.267c0,16.862,14.138,30.597,31,30.597h260.756L29.879,122.647C13.443,123.128,0,136.499,0,153.059z" />
            </g>
          </svg>
          <div class="text-neutral-400 font-medium mb-1">No videos found</div>
          <a routerLink="/video/templates/" class="text-brand hover:text-mid transition-colors text-sm font-light">
            Create video
          </a>
        </div>
      </ng-template>
      <ng-container *ngIf="loading">
        <div *ngFor="let _ of [1, 2, 3]" class="flex items-center gap-4 p-2">
          <div class="h-[5.55rem] aspect-video bg-neutral-100 rounded-lg shrink-0 animate-pulse"></div>
          <div class="grow">
            <div class="h-5 max-w-[11rem] bg-neutral-100 animate-pulse rounded"></div>
            <div class="flex items-center gap-2 mt-2">
              <ng-icon name="heroCalendar" class="text-neutral-500 text-sm h-4 w-4 mb-0.5" aria-hidden="true" />
              <div class="h-4 max-w-[6rem] bg-neutral-100 animate-pulse rounded w-full"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</ui-card>
