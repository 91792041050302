import { Injectable } from '@angular/core'
import { VideoApiService } from './video-api.service'
import { Observable, ReplaySubject, map, switchMap, take } from 'rxjs'
import { Video } from '../interfaces/video.interface'
import { Render } from '../interfaces/render'
import { TrackV0_0_0, VideoComponentV0_0_0, VideoDataV0_0_0 } from '../interfaces/template/v0_0_0'
import { MinimalVideo } from '../interfaces/minimal.video'
import { MinimalOrganisation } from '../interfaces/organisation.interface'
import { OrganisationService } from './organisation/organisation.service'
import { YouTubeUrlRegex } from '../components/edit-video-preview/previews/video/youtube'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { RenderPreviewDialogComponent } from '../dialogs/render-preview-dialog/render-preview-dialog.component'
import { AiOptions } from '../layouts/ai-creation-video-options-layout/ai-creation-video-options-layout.component'
import { environment } from 'src/app/environments/environment'

interface RenderProgressObject {
  render: Render
  subject: ReplaySubject<Render>
  timeout: unknown // node.js timeout
}

export interface PaginatedRenderFilter {
  page?: number
}

export interface ComponentError {
  component: ComponentVersion
  errors: string[]
}

type TemplateVersion = VideoDataV0_0_0 // | OtherTemplateVersion
type TrackVersion = TrackV0_0_0
type ComponentVersion = VideoComponentV0_0_0
@Injectable({
  providedIn: 'root',
})
export class VideoService {
  constructor(
    private videoApiService: VideoApiService,
    private organisationService: OrganisationService,
    private overlay: Overlay
  ) {}

  public renderErrorModalOpen = false

  private MAX_VIDEO_LENGTH = 300

  public listTemplates(organisationUuid: string | undefined | null) {
    return this.videoApiService.listTemplates(organisationUuid)
  }

  public createVideo(template: Video | MinimalVideo) {
    return this.videoApiService.createVideo(template)
  }

  public renderVideo(video: Video, outputResolution: { width: number; height: number }) {
    return this.organisationService.getActiveOrganisation().pipe(
      take(1),
      switchMap((organisation: MinimalOrganisation | null) => {
        if (!organisation) throw new Error('No active organisation')

        return this.videoApiService.renderVideo(video, outputResolution, organisation.uuid)
      })
    )
  }

  private renderProgressObservables: { [key: string]: RenderProgressObject } = {}
  public getRenderProgress(renderUuid: string): Observable<Render> {
    const existingSubject = this.renderProgressObservables[renderUuid]
    if (existingSubject) {
      return existingSubject.subject.asObservable()
    }

    const subject = new ReplaySubject<Render>(1)
    const timeout = setInterval(() => {
      this._setRenderProgressSubject(renderUuid, subject, timeout)
    }, 2000)

    this._setRenderProgressSubject(renderUuid, subject, timeout)

    return subject.asObservable()
  }

  private _setRenderProgressSubject(renderUuid: string, subject: ReplaySubject<Render>, timeout: unknown) {
    this.organisationService
      .getActiveOrganisation()
      .pipe(
        switchMap((organisation: MinimalOrganisation | null) => {
          if (!organisation) throw new Error('No active organisation')

          return this.videoApiService.getRenderProgress(renderUuid, organisation.uuid)
        })
      )
      .subscribe(render => {
        subject.next(render)
        if (render.status === 'done' || render.status === 'failed') {
          subject.complete()
          clearInterval(timeout as number)
          delete this.renderProgressObservables[renderUuid]
        }
      })
  }

  public listPreviousRenders(organisationUuid: string | undefined | null, filters: PaginatedRenderFilter = {}) {
    if (!organisationUuid) throw new Error('No organisation uuid provided')
    return this.videoApiService.listPreviousRenders(organisationUuid, filters)
  }

  public getTemplateVersionInterface(template: Video): TemplateVersion {
    switch (template.data.templateVersion) {
      case '0.0.0':
        return template.data as VideoDataV0_0_0

      default:
        throw new Error(`Unknown template version ${template.data.templateVersion}`)
    }
  }

  public getVideoTracks(video: Video) {
    const data = this.getTemplateVersionInterface(video)

    switch (data.templateVersion) {
      case '0.0.0':
        return (data as VideoDataV0_0_0).tracks

      default:
        throw new Error(`Unknown template version ${data.templateVersion}`)
    }
  }

  public getTrackComponents(video: Video, track: TrackVersion) {
    const data = this.getTemplateVersionInterface(video)

    switch (data.templateVersion) {
      case '0.0.0':
        track = track as TrackV0_0_0
        return track.components

      default:
        throw new Error(`Unknown template version ${data.templateVersion}`)
    }
  }

  public updateComponentById(
    componentId: string,
    component: ComponentVersion,
    video: Video,
    createIfNotFound = false
  ): Video {
    const data = this.getTemplateVersionInterface(video)
    let tracks
    let added = false

    switch (data.templateVersion) {
      case '0.0.0':
        tracks = this.getVideoTracks(video)
        for (const track of tracks) {
          const trackComponents = this.getTrackComponents(video, track)
          const componentIndex = trackComponents.findIndex(c => c.id === componentId)
          if (componentIndex === -1) continue

          trackComponents[componentIndex] = component
          added = true
          break
        }

        if (!added && createIfNotFound) {
          return this.addExistingComponentToTemplate(video, component)
        }

        return video

      default:
        throw new Error(`Unknown template version ${data.templateVersion}`)
    }
  }

  public getVideoByUuid(uuid: string) {
    return this.organisationService.getActiveOrganisation().pipe(
      take(1),
      switchMap((organisation: MinimalOrganisation | null) => {
        if (!organisation) throw new Error('No active organisation')

        return this.videoApiService.getVideo(uuid, organisation.uuid)
      })
    )
  }

  public getVideoStoryboard(videoUrl: string) {
    return this.videoApiService.getVideoStoryboard(videoUrl)
  }

  public getInvalidComponents(video: Video): ComponentError[] {
    const tracks = this.getVideoTracks(video)
    const invalidComponents: ComponentError[] = []

    for (const track of tracks) {
      const trackComponents = this.getTrackComponents(video, track)
      for (const component of trackComponents) {
        const errors = this.validateComponent(component)
        if (errors.length) {
          invalidComponents.push({ component, errors })
        }
      }
    }

    return invalidComponents
  }

  public validateComponent(component: ComponentVersion): string[] {
    const errors: string[] = []

    switch (component.classname) {
      case 'video.components.media.YouTubeComponent':
        errors.push(...this.validateYouTubeComponent(component))
        break

      case 'video.components.text.TranscriptionComponent':
        break

      default:
        errors.push(`Component class not found: ${component.classname}`)
        break
    }

    return errors
  }

  private validateYouTubeComponent(component: ComponentVersion): string[] {
    const errors: string[] = []

    const url = component.data['url'] as string | undefined

    if (!url) {
      errors.push('YouTube video id not found')
    }

    if (url && !YouTubeUrlRegex.test(url)) {
      errors.push('Invalid YouTube video id')
    }

    const startTime = component.data['startTime'] as number | undefined

    if (startTime && startTime < 0) {
      errors.push('Start time must be greater than or equal to 0')
    }

    const subclipStart = component.data['subclipStart'] as number | undefined
    const subclipEnd = component.data['subclipEnd'] as number | undefined

    if (subclipStart && subclipEnd && subclipStart >= subclipEnd) {
      errors.push('Subclip start time must be less than subclip end time')
    }

    if ((subclipEnd ?? 0) - (subclipStart ?? 0) > this.MAX_VIDEO_LENGTH) {
      console.log(subclipEnd, subclipStart)
      errors.push(`Subclip length must be less than or equal to ${this.MAX_VIDEO_LENGTH} seconds`)
    }

    return errors
  }

  private overlayRef: OverlayRef | null = null
  public openVideoPreviewDialog(render: Render) {
    if (!render.output || this.overlayRef) return

    const overlay = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      scrollStrategy: this.overlay.scrollStrategies.block(),
    })

    this.overlayRef = overlay

    const portal = new ComponentPortal(RenderPreviewDialogComponent)
    const componentRef = overlay.attach(portal)

    componentRef.instance.render = render

    overlay.backdropClick().subscribe(() => {
      this.closeOverlay()
    })

    componentRef.instance.closeDialog = () => {
      this.closeOverlay()
    }

    overlay.detachments().subscribe(() => {
      this.overlayRef = null
    })

    overlay.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        this.closeOverlay()
      }
    })
  }

  private closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.dispose()
      this.overlayRef = null
    }
  }

  /**
   * Gets the subtitle component from the template
   * @param template The template to get the subtitle component from
   */
  public getSubtitleComponentFromTemplate(template: Video['data']) {
    const COMPONENT_CLASSNAME = 'video.components.text.TranscriptionComponent'
    const tracks = template.tracks
    for (const track of tracks) {
      const components = track.components
      for (const component of components) {
        if (component.classname === COMPONENT_CLASSNAME) {
          return component
        }
      }
    }

    return null
  }

  public addComponentToTemplate(template: Video, componentClassname: string) {
    const data = this.getTemplateVersionInterface(template)

    switch (data.templateVersion) {
      case '0.0.0':
        return this.addComponentToTrackV_0_0_0(template, componentClassname)

      default:
        throw new Error(`Unknown template version ${data.templateVersion}`)
    }
  }

  public addExistingComponentToTemplate(template: Video, component: ComponentVersion) {
    const data = this.getTemplateVersionInterface(template)

    switch (data.templateVersion) {
      case '0.0.0':
        return this.addExistingComponentToTrackV_0_0_0(template, component)

      default:
        throw new Error(`Unknown template version ${data.templateVersion}`)
    }
  }

  private addComponentToTrackV_0_0_0(template: Video, componentClassname: string, trackIndex = -1) {
    const data = this.getTemplateVersionInterface(template)

    const tracks = this.getVideoTracks(template)
    if (trackIndex === -1) {
      trackIndex = tracks.length - 1
    }

    const track = tracks[trackIndex]
    const newComponent = this.createEmptyComponent(componentClassname, template)
    track.components.push(newComponent)

    return template
  }

  private addExistingComponentToTrackV_0_0_0(template: Video, component: ComponentVersion, trackIndex = -1) {
    const tracks = this.getVideoTracks(template)
    if (trackIndex === -1) {
      trackIndex = tracks.length - 1
    }

    const track = tracks[trackIndex]
    track.components.push(component)

    return template
  }

  public createEmptyComponent(classname: string, template: Video): ComponentVersion {
    switch (classname) {
      case 'video.components.text.TranscriptionComponent':
        return {
          classname,
          width: template.data.metadata.width,
          height: template.data.metadata.height,
          startTime: 0,
          duration: 0,
          templateVersion: template.data.templateVersion,
          id: '_subtitles',
          data: {
            font: 'Montserrat-Bold',
            fontSize: 128,
            color: '#ffffff',
            strokeColor: '#000000',
            strokeWidth: 16,
          },
        }
      default:
        throw new Error(`Unknown component classname ${classname}`)
    }
  }

  public removeComponentFromTemplate(template: Video, componentId: ComponentVersion['id']) {
    const data = this.getTemplateVersionInterface(template)

    switch (data.templateVersion) {
      case '0.0.0':
        return this.removeComponentFromTrackV_0_0_0(template, componentId)

      default:
        throw new Error(`Unknown template version ${data.templateVersion}`)
    }
  }

  private removeComponentFromTrackV_0_0_0(template: Video, componentId: ComponentVersion['id']) {
    const data = this.getTemplateVersionInterface(template)

    const tracks = this.getVideoTracks(template)
    for (const track of tracks) {
      const components = this.getTrackComponents(template, track)
      const componentIndex = components.findIndex(c => c.id === componentId)
      if (componentIndex === -1) continue

      components.splice(componentIndex, 1)
      break
    }

    return template
  }

  public createAiVideo(organisationUuid: string, videoUrl: string, options: AiOptions) {
    return this.videoApiService.createAiVideo(organisationUuid, videoUrl, options)
  }

  public getVideoUrlSuitableForAi(videoUrl: string) {
    return this.videoApiService.getVideoUrlSuitableForAi(videoUrl)
  }

  public getVideoDuration(template: Video) {
    const data = this.getTemplateVersionInterface(template)

    switch (data.templateVersion) {
      case '0.0.0':
        return this.getVideoDurationV_0_0_0(template)

      default:
        throw new Error(`Unknown template version ${data.templateVersion}`)
    }
  }

  private getVideoDurationV_0_0_0(template: Video) {
    const tracks = this.getVideoTracks(template)
    let duration = 0

    for (const track of tracks) {
      const components = this.getTrackComponents(template, track)
      for (const component of components) {
        if (component.startTime + component.duration > duration) {
          duration = component.startTime + component.duration
        }
      }
    }

    return duration
  }

  public uploadVideo(file: File, organisationUuid: string) {
    return this.videoApiService.uploadVideo(file, organisationUuid)
  }

  public getComponentById(template: Video, componentId: string): ComponentVersion | null {
    const data = this.getTemplateVersionInterface(template)

    switch (data.templateVersion) {
      case '0.0.0':
        return this.getComponentByIdV_0_0_0(template, componentId)

      default:
        throw new Error(`Unknown template version ${data.templateVersion}`)
    }
  }

  private getComponentByIdV_0_0_0(template: Video, componentId: string): ComponentVersion | null {
    const tracks = this.getVideoTracks(template)
    for (const track of tracks) {
      const components = this.getTrackComponents(template, track)
      const component = components.find(c => c.id === componentId)
      if (component) return component
    }

    return null
  }

  public listProjects({ organisation }: { organisation: string }) {
    return this.videoApiService.listProjects({ organisation })
  }

  private getRenderDownloadUrl(render: Render) {
    return this.videoApiService.getRenderDownloadUrl(render.uuid)
  }

  public downloadClip(render: Render) {
    this.getRenderDownloadUrl(render).subscribe(url => {
      const a = document.createElement('a')
      a.href = url
      a.download = 'clip.mp4'
      a.click()
    })
  }
}
