import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay'
import { Component } from '@angular/core'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { AiCreationDialogComponent } from '../../dialogs/ai-creation-dialog/ai-creation-dialog.component'
import { ComponentPortal } from '@angular/cdk/portal'
import { CardModule } from 'src/app/ui/card/card.module'

@Component({
  selector: 'main-ai-creation',
  standalone: true,
  imports: [IconModule, OverlayModule, AiCreationDialogComponent, CardModule],
  templateUrl: './ai-creation.component.html',
  styleUrl: './ai-creation.component.scss',
})
export class AiCreationComponent {
  constructor(private overlay: Overlay) {}
  private overlayRef: OverlayRef | null = null

  public openCreationOverlay() {
    this.closeOverlay()

    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy,
      backdropClass: 'blurred-bg',
    })
    const componentRef = this.overlayRef.attach(new ComponentPortal(AiCreationDialogComponent))

    this.overlayRef.backdropClick().subscribe(() => {
      componentRef.instance.closeOverlay()
    })

    componentRef.instance.closeDialog.subscribe(() => this.closeOverlay())

    this.overlayRef.detachments().subscribe(() => {
      this.overlayRef = null
    })

    this.overlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        componentRef.instance.closeOverlay()
      }
    })
  }

  private closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.detach()
    }
  }
}
