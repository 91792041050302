import { Component, OnInit, signal } from '@angular/core'
import { VideoService } from '../../services/video.service'
import { OrganisationService } from '../../services/organisation/organisation.service'
import { catchError, interval, map, of, switchMap, tap } from 'rxjs'
import { Render } from '../../interfaces/render'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { AiCreationDialogComponent } from '../../dialogs/ai-creation-dialog/ai-creation-dialog.component'
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms'
import { CardModule } from 'src/app/ui/card/card.module'
import { IconModule } from 'src/app/ui/icon/icon.module'
import { NgxTippyModule } from 'ngx-tippy-wrapper'
import { CommonModule } from '@angular/common'
import { Project } from '../../interfaces/project'
import { RouterModule } from '@angular/router'

export const VIDEO_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?t=\d+)?|(?:https?:\/\/)?(?:www\.)?twitch\.tv\/[a-zA-Z0-9_]+\/clip\/([a-zA-Z0-9_-]+)/

@Component({
  selector: 'main-recent-videos',
  templateUrl: './recent-videos.component.html',
  styleUrls: ['./recent-videos.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, CardModule, IconModule, NgxTippyModule, CommonModule, RouterModule],
})
export class RecentVideosComponent implements OnInit {
  constructor(
    private videoService: VideoService,
    private organisationService: OrganisationService,
    private overlay: Overlay
  ) {}

  protected loading = false
  protected recentProjects: Project[] = []
  protected cachedOrganisationUuid: string | null = null
  protected error = false

  protected videoUrl = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, Validators.pattern(VIDEO_REGEX)],
  })

  protected placeholder = signal('')

  protected selectedVideo = signal<File | null>(null)

  ngOnInit(): void {
    this.organisationService.getActiveOrganisation().subscribe(organisation => {
      this.error = false
      if (organisation?.uuid === this.cachedOrganisationUuid) return
      this.loading = true
      this.recentProjects = []

      if (!organisation) return

      // this.videoService
      //   .listPreviousRenders(organisation.uuid)
      //   .pipe(
      //     catchError(() => {
      //       this.error = true
      //       this.loading = false
      //       return of(null)
      //     }),
      //     tap(() => (this.loading = false))
      //   )
      //   .subscribe(videos => {
      //     if (!videos) return
      //     this.recentVideos = videos.results.slice(0, 3)
      //   })

      this.videoService
        .listProjects({ organisation: organisation.uuid })
        .pipe(
          catchError(() => {
            this.error = true
            this.loading = false
            return of(null)
          }),
          tap(() => (this.loading = false))
        )
        .subscribe(projects => {
          if (!projects) return
          this.recentProjects = projects.results.slice(0, 3)
        })
    })

    let currentPlaceholderIndex = 0
    const placeholderText = ['Paste YouTube URL', 'Paste Twitch URL']
    interval(5 * 1000).subscribe(() => {
      this.placeholder.set(placeholderText[currentPlaceholderIndex] + ' or drop video')
      currentPlaceholderIndex = (currentPlaceholderIndex + 1) % placeholderText.length
    })
    this.placeholder.set(placeholderText[currentPlaceholderIndex] + ' or drop video')
    currentPlaceholderIndex = (currentPlaceholderIndex + 1) % placeholderText.length
  }

  protected getDateString(created: string): string {
    const created_now_buffer = 60
    const is_created_now = new Date(created).getTime() > Date.now() - created_now_buffer * 1000
    if (is_created_now) return 'just now'

    const minutes = Math.floor((Date.now() - new Date(created).getTime()) / 60000)
    if (minutes < 60) return `${minutes} minutes ago`

    const hours = Math.floor(minutes / 60)
    if (hours < 24) return `${hours} hours ago`

    const days = Math.floor(hours / 24)
    if (days < 7) return `${days} days ago`

    return new Date(created).toLocaleDateString()
  }

  protected openVideoPreviewDialog(render: Render): void {
    if (!render.output) return

    this.videoService.openVideoPreviewDialog(render)
  }

  protected openUploadDialog(): void {
    this.selectedVideo.set(null)
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'video/*'
    input.onchange = () => {
      if (!input.files) return
      this.selectedVideo.set(input.files[0])
      this.openCreationOverlay()
    }

    input.click()
  }

  private overlayRef: OverlayRef | null = null

  protected openCreationOverlay() {
    this.closeOverlay()

    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy,
      backdropClass: 'blurred-bg',
    })
    const componentRef = this.overlayRef.attach(new ComponentPortal(AiCreationDialogComponent))
    if (this.selectedVideo()) {
      componentRef.instance.videoFile = this.selectedVideo()
    } else {
      componentRef.instance.videoUrl = this.videoUrl.getRawValue()
    }

    this.overlayRef.backdropClick().subscribe(() => {
      componentRef.instance.closeOverlay()
    })

    componentRef.instance.closeDialog.subscribe(() => this.closeOverlay())

    this.overlayRef.detachments().subscribe(() => {
      this.overlayRef = null
    })

    this.overlayRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        componentRef.instance.closeOverlay()
      }
    })
  }

  private closeOverlay() {
    if (this.overlayRef) {
      this.overlayRef.detach()
      this.selectedVideo.set(null)
    }
  }
}
