import { Injectable } from '@angular/core'
import { VideoCreationApiService } from './video-creation-api.service'
import { CreateProjectInput } from '../../interfaces/project'

const YOUTUBE_REGEX = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?t=\d+)?/

@Injectable({
  providedIn: 'root',
})
export class VideoCreationService {
  constructor(private videoCreationApi: VideoCreationApiService) {}

  // public getYouTubeVideoInfo(videoId: string) {
  //   return this.videoCreationApi.getYouTubeVideoInfo(videoId)
  // }

  private getYouTubeVideoInfo(videoUrl: string) {
    const videoId = this.getYouTubeVideoId(videoUrl)
    return this.videoCreationApi.getYouTubeVideoInfo(videoId)
  }

  private getTwitchVideoInfo(videoUrl: string) {
    const videoId = this.getTwitchClipID(videoUrl)
    return this.videoCreationApi.getTwitchVideoInfo(videoId)
  }

  public getVideoInfo(videoUrl: string) {
    if (this.isYouTubeUrl(videoUrl)) {
      return this.getYouTubeVideoInfo(videoUrl)
    }
    if (this.isTwitchUrl(videoUrl)) {
      return this.getTwitchVideoInfo(videoUrl)
    }

    return null
  }

  private isYouTubeUrl(videoUrl: string) {
    return videoUrl.includes('youtube.com') || videoUrl.includes('youtu.be')
  }

  private isTwitchUrl(videoUrl: string) {
    return videoUrl.includes('twitch.tv')
  }

  private getYouTubeVideoId(videoUrl: string) {
    const match = videoUrl.match(YOUTUBE_REGEX)
    return match ? match[1] : ''
  }

  private getTwitchClipID(videoUrl: string) {
    const clipRegex = /twitch\.tv\/[a-zA-Z0-9_]+\/clip\/([a-zA-Z0-9_-]+)/
    const match = videoUrl.match(clipRegex)
    return match ? match[1] : ''
  }

  // public createAiProject(data: {
  //   videoUrl?: string
  //   uploadedVideoUuid?: string
  //   themes: string[]
  //   selectedCaptions: string
  //   organisationUuid: string
  // }) {
  //   return this.videoCreationApi.createAiProject(data)
  // }

  public createProject(data: CreateProjectInput) {
    return this.videoCreationApi.createProject(data)
  }

  public generateClipsForProject({
    projectUuid,
    organisationUuid,
    themes,
    selectedCaptions,
  }: {
    projectUuid: string
    organisationUuid: string
    themes: string[]
    selectedCaptions: string
  }) {
    return this.videoCreationApi.generateClipsForProject({
      projectUuid,
      organisationUuid,
      themes,
      selectedCaptions,
    })
  }
}
