import { Component, Input, Output } from '@angular/core'
import { Render } from '../../interfaces/render'
import { VideoService } from '../../services/video.service'

@Component({
  selector: 'app-render-preview-dialog',
  templateUrl: './render-preview-dialog.component.html',
  styleUrls: ['./render-preview-dialog.component.scss'],
})
export class RenderPreviewDialogComponent {
  constructor(private videoService: VideoService) {}

  @Input({ required: true })
  public render!: Render

  @Output()
  public closeDialog = () => {}

  protected showVideo = false

  protected get aspectRatio(): number {
    return this.render.videoX / this.render.videoY
  }

  protected downloadVideo(): void {
    this.videoService.downloadClip(this.render)
  }
}
