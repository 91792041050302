<ui-card class="w-fit p-6">
  <h1 class="text-darker text-xl font-bold">Preview of {{ render.title }}</h1>
  <div #videoContainer class="h-96 mx-auto mt-4" [ngStyle]="{ aspectRatio }">
    <div
      *ngIf="!showVideo"
      [ngStyle]="{ aspectRatio }"
      class="bg-neutral-100 rounded-xl flex items-center justify-center h-96 animate-pulse">
      <img [src]="render.previewThumbnail" class="h-full select-none rounded-xl" />
    </div>
    <video
      class="h-96 rounded-xl"
      controls
      autoplay
      (loadeddata)="showVideo = true"
      [ngClass]="{ 'opacity-0': !showVideo }">
      <source [src]="render.output" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </div>
  <div class="flex items-center gap-4 mt-4 justify-end mx-auto" [ngStyle]="{ 'width.px': videoContainer?.offsetWidth }">
    <!-- <a *ngIf="showVideo" class="button-primary text-xs" [href]="render.output" download="video.mp4" target="_blank">
      Open video
    </a>
    <span *ngIf="!showVideo" class="button-primary text-xs disabled"> Open video </span> -->
    <button class="button-primary text-xs" [disabled]="!showVideo" (click)="downloadVideo()">Download video</button>
  </div>
</ui-card>
