<!-- <p class="font-semibold text-dark mb-2">Number of clips to generate</p>
<p
  *ngIf="clipCountInvalid | async as invalid"
  class="text-sm text-dark mb-4 flex items-center gap-1"
  [ngClass]="{ 'line-through': invalid.invalid }">
  Estimated processing time:
  <ng-container>
    @switch (clipCount()) { @case(1) {
    <span class="text-brand">5 minutes</span>
    } @case(2) {
    <span class="text-yellow-700">10 minutes</span>
    } @case(3) {
    <span class="text-red-800">15 minutes</span>
    } }
  </ng-container>
  <ng-icon
    *ngIf="invalid.invalid"
    name="heroExclamationTriangle"
    class="w-4 h-4 text-red-800 text-xl ml-1"
    [ngxTippy]="'You do not have enough processing minutes left to generate this many clips.'" />
</p>
<ui-slider [max]="3" [min]="1" (valueChange)="clipCount.set($event)" /> -->
<p class="font-semibold text-dark mt-4">Clip theme</p>
<p class="text-sm text-dark font-light mb-4">Optional: Choose one or more themes for your clips</p>
<div class="flex flex-wrap gap-1 mb-4">
  @for (theme of themes; track $index) {
  <button
    class="border border-neutral-200 rounded-lg py-1 px-2 text-neutral-400 text-xs font-light transition-all"
    [ngClass]="{ 'bg-brand text-white': isSelected(theme) }"
    (click)="toggleTheme(theme)">
    {{ theme }}
  </button>
  }
</div>
<!-- <textarea
  class="input text-sm px-2 py-1 w-full font-light"
  [formControl]="form.controls.theme"
  placeholder="Enter a custom theme for your clips"
  rows="3"
  maxlength="100"
  style="resize: none"></textarea> -->
<!--
<p class="font-semibold text-dark mb-0 mt-4">Subtitles</p>
<div class="flex gap-2 items-center">
  <ui-toggle-slider (toggledChange)="onSubtitleChange($event)" [toggled]="form.controls.subtitles.value" class="mt-2" />
  <p class="text-sm text-dark mt-2 cursor-default" (click)="onSubtitleChange(!form.controls.subtitles.value)">
    Subtitles {{ form.controls.subtitles.value ? 'enabled' : 'disabled' }}
  </p>
</div> -->
